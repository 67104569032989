import { getScrollbarWidth } from '@util/getScrollbarWidth';
import { trapFocus } from '@util/trapFocus';

interface IButtonText {
    open: string;
    close: string;
}

export const useMainMenu = (headerElement: HTMLElement): void => {
    const element = document.querySelector('#main-navigation') as HTMLElement;
    const toggleButtonElement = document.querySelector('.main-navigation__toggle-button') as HTMLButtonElement;
    const contentElement = document.querySelector('.main-navigation__content') as HTMLDivElement;
    const backdropElement = document.querySelector('.main-navigation__backdrop') as HTMLDivElement;
    const itemsElement = document.querySelector('.main-navigation__items') as HTMLDivElement;
    let isOpen: boolean = false;

    const toggleState = (): void => {
        if (!isOpen) {
            open();
        } else {
            close();
        }
    };

    const open = (): void => {
        contentElement.classList.add('main-navigation__content--open');
        document.body.classList.add('main-navigation-is-open', 'has-full-screen-modal');
        offsetHeaderPosition();

        setTimeout((): void => {
            backdropElement.classList.add('main-navigation__backdrop--visible');
            itemsElement.classList.add('main-navigation__items--visible');
        }, 10);

        document.addEventListener(`keydown`, initializeTrapFocus);
    
        toggleButton();
        isOpen = true;
    };

    const close = (): void => {
        itemsElement.classList.remove('main-navigation__items--visible');
        backdropElement.classList.remove('main-navigation__backdrop--visible');
        document.body.classList.remove('main-navigation-is-open', 'has-full-screen-modal');
        document.documentElement.style.removeProperty('--scrollbar-width');

        resetHeaderPosition();

        setTimeout((): void => {
            contentElement.classList.remove('main-navigation__content--open');
        }, 500);
    
        document.removeEventListener(`keydown`, initializeTrapFocus);
    
        toggleButton();
        isOpen = false;
    };

    const closeOnOutsideClick = (event: MouseEvent): void => {
        if (event.target instanceof Element) {
            let clickedElement: Element = event.target;

            if (clickedElement === backdropElement) {
                close();
            }
        }
    };

    const closeOnEsc = (event: KeyboardEvent): void => {
        if (event.defaultPrevented) {
            return;
        }
    
        if (event.key === 'Escape') {
            close();
        }
        event.preventDefault();
    };

    const offsetHeaderPosition = (): void => {
        const scrollbarWidth: number = getScrollbarWidth();
    
        document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth + "px");
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        headerElement.style.paddingRight = `${scrollbarWidth}px`;
        headerElement.style.marginRight = `-${scrollbarWidth}px`;
        toggleButtonElement.style.marginRight = `${scrollbarWidth}px`;
    };

    const resetHeaderPosition = (): void => {
        document.body.removeAttribute('style');
        headerElement.removeAttribute('style');
        toggleButtonElement.removeAttribute('style');
    }

    const toggleButton = (): void => {
        const buttonStateTextElement = toggleButtonElement.querySelector('.main-navigation__state-text') as HTMLSpanElement;
        const buttonMenuTextElement = toggleButtonElement.querySelector('.main-navigation__menu-text') as HTMLSpanElement;
    
        const data: string = toggleButtonElement.dataset.includeText!;
        const dataArray: string[] = data?.split(', ');
        const buttonStateText: IButtonText = {
            open: dataArray[0],
            close: dataArray[1]
        };
    
        if(!toggleButtonElement.classList.contains('main-navigation__toggle-button--expanded')) {
            toggleButtonElement.classList.add('main-navigation__toggle-button--expanded');
            toggleButtonElement.setAttribute('aria-expanded', 'true');
            buttonStateTextElement.textContent = buttonStateText.close;
            buttonStateTextElement.classList.remove('sr-only');
            buttonMenuTextElement.classList.add('sr-only');
        } else {
            toggleButtonElement.classList.remove('main-navigation__toggle-button--expanded');
            toggleButtonElement.setAttribute('aria-expanded', 'false');
            buttonStateTextElement.textContent = buttonStateText.open;
            buttonStateTextElement.classList.add('sr-only');
            buttonMenuTextElement.classList.remove('sr-only');
        }
    }

    const initializeTrapFocus = (event: KeyboardEvent): void => {
        trapFocus(event, element);
    }
    
    const initialize = (): void => {
        toggleButtonElement.addEventListener('click', (): void => {
            toggleState();
        });

        document.addEventListener('click', (event: MouseEvent): void => {
            if (isOpen) {
                closeOnOutsideClick(event);
            }
        });

        window.addEventListener('keyup', (event: KeyboardEvent): void => {
            if (isOpen) {
                closeOnEsc(event);
            }
        });
    }
    
    initialize();
}
