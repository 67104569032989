import { debounce } from "@util/debounce";
import Disturbance from "@features/Layout/Features/Header/Features/Disturbance/scripts/Disturbance";
import ToolMenu from "@features/Layout/Features/Header/Features/ToolMenu/scripts/ToolMenu";
import { useMainMenu } from "@features/Layout/Features/Header/scripts/mainNavigation";
import Menu from "@features/Layout/Features/Header/Features/Menu/scripts/Menu";

enum Direction {
    Up,
    Down
}

const useHeader = (): void => {
    const element = document.querySelector('.header') as HTMLElement;
    let elementHeight: number = 0;
    let bodyRect: number = 0;
    let scrollDirection: Direction = Direction.Down;
    let scrollPosition: number = Math.ceil(window.scrollY);
    let headerHasFocus: boolean = false;

    const setScrollDirection = (): void => {
        if ((document.body.getBoundingClientRect()).top > bodyRect) {
            scrollDirection = Direction.Up;
        } else {
            scrollDirection = Direction.Down;
        }
    
        bodyRect = (document.body.getBoundingClientRect()).top;
    };

    const checkIfHeaderHasFocus = (event: FocusEvent): void => {
        if (event.target instanceof HTMLElement) {
            const elementWithFocus = event.target as HTMLElement;

            if (element?.contains(elementWithFocus)) {
                headerHasFocus = true;
            } else {
                headerHasFocus = false;
            }
        }
    };

    const toggleVisibility = (): void => {
            if (Math.ceil(window.scrollY) > scrollPosition + elementHeight) {
            hide();
        }
    
        if (scrollDirection === Direction.Up || scrollDirection === Direction.Down && Math.ceil(window.scrollY) + window.innerHeight >= document.body.scrollHeight || document.body.classList.contains('main-navigation-is-open') || headerHasFocus) {
            show();
            scrollPosition = Math.ceil(window.scrollY);
        }
    };

    const hide = (): void => {
        if (!element?.classList.contains('header--hidden')) {
            element?.classList.add('header--hidden');
        }
    }
    
    const show = (): void => {
        if (element?.classList.contains('header--hidden')) {
            element?.classList.remove('header--hidden');
        }
    }

    const initialize = (): void => {
        if (element) {
            elementHeight = element.offsetHeight;
        }

        new Disturbance();
        new ToolMenu();
        useMainMenu(element);
        new Menu();

        window.addEventListener('scroll', debounce(() => {
            setScrollDirection();
            toggleVisibility();
        }, 100));

        window.addEventListener('focus', (event) => {
            checkIfHeaderHasFocus(event);
            toggleVisibility();
        }, true);
    };
    
    initialize();
}

document.addEventListener('DOMContentLoaded', () => {
    useHeader();
});
