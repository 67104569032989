export default class ToolMenu {
  readonly dropdownButtons: NodeListOf<HTMLButtonElement>;
  readonly languageLinks: NodeListOf<Element>;

  constructor() {
    this.dropdownButtons = document.querySelectorAll('.tool-menu__item--has-dropdown > .tool-menu__link') as NodeListOf<HTMLButtonElement>;
    this.languageLinks = document.querySelectorAll(".language-menu__link");

    this.init();
  }

  private init(): void {
    if (this.dropdownButtons.length > 0) {
      this.dropdownButtons.forEach(button => {
        button.addEventListener('click', (): void => {
          this.toggleDropdown(button);
        });
      });
    }

    if (!!this.languageLinks) {
      this.languageLinks.forEach(link => {
        link.addEventListener('click', (e: Event) => {
          e.preventDefault();
          window.location.href = (link as HTMLAnchorElement).href + window.location.search;
        });
      });
    }
  }

  private toggleDropdown(button: HTMLButtonElement): void {
    const dropdown = button.nextElementSibling as HTMLDivElement;

    if (!dropdown.classList.contains('tool-menu__dropdown--open')) {
      this.openDropdown(dropdown);
      this.toggleButton(button);
    } else {
      this.closeDropdown(dropdown);
      this.toggleButton(button);
    }
  }

  private openDropdown(dropdown: HTMLDivElement): void {
    dropdown.classList.add('tool-menu__dropdown--open');
  }

  private closeDropdown(dropdown: HTMLDivElement): void {
    dropdown.classList.remove('tool-menu__dropdown--open');
  }

  private toggleButton(button: HTMLButtonElement): void {
    if (!button.classList.contains('tool-menu__link--expanded')) {
      button.classList.add('tool-menu__link--expanded');
      button.setAttribute('aria-expanded', 'true');
    } else {
      button.classList.remove('tool-menu__link--expanded');
      button.setAttribute('aria-expanded', 'false');
    }
  }
}
