interface IButtonText {
    pageName: string;
    expand: string;
    minimize: string;
}

export default class Menu {
    readonly subMenuToggleButtons: NodeListOf<HTMLButtonElement>;
    
    constructor() {
        this.subMenuToggleButtons = document.querySelectorAll('.menu__submenu-toggle-button ') as NodeListOf<HTMLButtonElement>;
        
        this.init();
    }
    
    private init(): void {
        if (this.subMenuToggleButtons.length > 0) {
            this.subMenuToggleButtons.forEach(button => {
                button.addEventListener('click', (): void => {
                    this.toggleSubmenu(button);
                });
            });
        }
    }

    private toggleSubmenu(button: HTMLButtonElement): void {
        const itemContainer = button.parentElement as HTMLDivElement;
        const submenu = itemContainer.nextElementSibling as HTMLDivElement;

        if (!submenu.classList.contains('submenu--open')) {
            this.openSubmenu(submenu);
            this.toggleButton(button);
        } else {
            this.closeSubmenu(submenu);
            this.toggleButton(button);
        }
    }
    
    private openSubmenu(submenu: HTMLDivElement): void {
        submenu.classList.add('submenu--open');
    }

    private closeSubmenu(submenu: HTMLDivElement): void {
        submenu.classList.remove('submenu--open');
    }

    private toggleButton(button: HTMLButtonElement): void {
        const hiddenButtonText = button.querySelector('.sr-only') as HTMLSpanElement;

        const data: string = button.dataset.includeText!;
        const dataArray: string[] = data?.split(', ');
        const buttonText: IButtonText = {
            pageName: dataArray[0],
            expand: dataArray[1],
            minimize: dataArray[2]
        };

        if(!button.classList.contains('menu__submenu-toggle-button--expanded')) {
            button.classList.add('menu__submenu-toggle-button--expanded');
            button.setAttribute('aria-expanded', 'true');
            hiddenButtonText.textContent = `${buttonText.minimize} ${buttonText.pageName}`;
        } else {
            button.classList.remove('menu__submenu-toggle-button--expanded');
            button.setAttribute('aria-expanded', 'false');
            hiddenButtonText.textContent = `${buttonText.expand} ${buttonText.pageName}`;
        }
    }
}
