interface IDisturbanceCookieData {
    closed: boolean;
    id: string;
}
export default class Disturbance {
    readonly htmlElement: HTMLHtmlElement;
    readonly disturbanceElement: HTMLDivElement | null;
    
    constructor() {
        this.htmlElement = document.documentElement as HTMLHtmlElement;
        this.disturbanceElement = document.querySelector('.disturbance');
        
        this.init();
    }

    private init(): void {
        if (this.disturbanceElement) {
            const height = this.disturbanceElement.scrollHeight;
            this.htmlElement.style.setProperty('--disturbance-height', height + 'px');

            const closeButton = document.querySelector('.disturbance__close-button') as HTMLButtonElement;

            closeButton.addEventListener('click', (): void => {
                this.closeMessage();
            });
        }
    }
    
    private closeMessage(): void {
        this.disturbanceElement?.classList.add('disturbance--hidden');
        this.htmlElement.style.setProperty('--disturbance-height', 0 + 'px');
        const id = (this.disturbanceElement?.querySelector('#disturbance-id') as HTMLInputElement).value;

        if (id != null) {
            const cookieData: IDisturbanceCookieData = {
                closed: true,
                id: id
            };

            this.setCookie('jvm_disturbance', cookieData, 2); // Set the cookie to expire in 2 days 
        }
    }
    
    private setCookie(name: string, value: IDisturbanceCookieData, days: number): void {
        const expires = new Date();
        expires.setDate(expires.getDate() + days);
        const cookieValue = encodeURIComponent(JSON.stringify(value)) + `; expires=${expires.toUTCString()}; path=/`;
        document.cookie = `${name}=${cookieValue}`;
    }
}
