export const getScrollbarWidth = (): number => {
    const tmpDiv = document.createElement('div') as HTMLDivElement;
    tmpDiv.style.visibility = 'hidden';
    tmpDiv.style.overflow = 'scroll';
    document.body.appendChild(tmpDiv);
    const inner = document.createElement('div');
    tmpDiv.appendChild(inner);
    const scrollbarWidth = (tmpDiv.offsetWidth - inner.offsetWidth);
    tmpDiv.parentNode.removeChild(tmpDiv);

    return scrollbarWidth;
}