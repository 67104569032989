export const trapFocus = (event: KeyboardEvent, modal: HTMLElement): void => {
  const isTabPressed = event.key === 'Tab';
  if (!isTabPressed || event.defaultPrevented) {
    return;
  }
  const focusableElements = 'button, [href], input, select, textarea, iframe, [tabindex]:not([tabindex="-1"])';
  const focusableContent = Array.from(modal.querySelectorAll(focusableElements)) as HTMLElement[];
  const filteredFocusableContent = focusableContent.filter(element => element.offsetHeight > 0);

  const firstFocusableElement = filteredFocusableContent[0] as HTMLElement;
  const lastFocusableElement = filteredFocusableContent[filteredFocusableContent.length - 1] as HTMLElement;

  if (event.shiftKey) {
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      event.preventDefault();
    }
  } else if (document.activeElement === lastFocusableElement) {
    firstFocusableElement.focus();
    event.preventDefault();
  }
}
